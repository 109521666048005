import React from "react"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import CloseIcon from "@material-ui/icons/Close"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { connect } from 'formik'
import { useStateValue } from '../../../state'
import { saveProduct } from '../../../state/actions'

import Stepper from "./stepper"

import SaveButton from "../../partials/saveButton"

import { makeStyles } from "@material-ui/core/styles"

const Dot = styled.div`
  min-width: 12px;
  height: 12px;
  background: forestgreen;
  border-radius: 50%;
`

const Title = styled(Typography)`
  margin: 0px 8px;
`

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    backgroundColor: theme.palette.common.white,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}))

function Header({ onClose, onNext, onBack, pageIndex, title, formik }) {
  const { values } = formik
  const classes = useStyles()
  const [state, dispatch] = useStateValue()

  const handleSave = values => {
    if (!state.editProduct.id) {
      console.log("ERROR: no product selected")
      return
    }
  
    const newState = { ...state.editProduct, pdf_example_values: values }
    dispatch(saveProduct(newState))
  }

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Title variant="h6" className={classes.title}>
            dbunk
          </Title>
          {onClose && (
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <AppBar className={classes.appBar} color="default">
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={2}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Dot />
                <Title variant="body1">{title}</Title>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Stepper pageIndex={pageIndex} />
            </Grid>
            <Grid item xs={2}>
              <Button onClick={onBack} variant="outlined">
                Back
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Next
              </Button>
            </Grid>
            {!process.env.LIVE_MODE && (
              <Grid item xs={2}>
                <SaveButton onClick={() => handleSave(values)} />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default connect(Header)
