import React, { useEffect } from "react"
import { useStateValue } from "../../../state"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import Column from "./column.js"
import Button from "@material-ui/core/Button"
import styled from "styled-components"
import { getColumnType } from "../../../util/pages"
import SaveButton from "../../partials/saveButton"
import PageTitle from "../../partials/pageTitle"
import { saveProduct, getProduct } from "../../../state/actions"
import LinearProgress from "@material-ui/core/LinearProgress"
import Grid from "@material-ui/core/Grid"
import { get } from "lodash"

const Container = styled.div``

export default function Editor(props) {
  const { productId } = props
  const [state, dispatch] = useStateValue()
  const { editProduct } = state

  useEffect(() => {
    let requestSent = false
    if (!requestSent) dispatch(getProduct(productId))
    requestSent = true
  }, [])

  const onDragEnd = result => {
    const { destination, source, draggableId, type } = result

    if (!destination) {
      return
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    if (type === "column") {
      const newColumnOrder = Array.from(editProduct.jsondata.columnOrder)
      newColumnOrder.splice(source.index, 1)
      newColumnOrder.splice(destination.index, 0, draggableId)

      const newState = {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            columnOrder: newColumnOrder,
          },
        },
      }

      dispatch({
        type: "update",
        newState,
      })
      return
    }

    if (type.includes("fieldArray")) {
      const newTasks = { ...editProduct.jsondata.tasks }
      newTasks[source.droppableId].taskIds.splice(source.index, 1)
      newTasks[source.droppableId].taskIds.splice(
        destination.index,
        0,
        draggableId
      )

      const newState = {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            tasks: newTasks,
          },
        },
      }

      dispatch({
        type: "update",
        newState,
      })
      return
    }

    const start = editProduct.jsondata.columns[source.droppableId]
    const finish = editProduct.jsondata.columns[destination.droppableId]

    // moving tasks within a column
    if (start === finish) {
      const newTaskIds = Array.from(start.taskIds)
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        taskIds: newTaskIds,
      }

      const newState = {
        ...state,
        editProduct: {
          ...state.editProduct,
          jsondata: {
            ...state.editProduct.jsondata,
            columns: {
              ...state.editProduct.jsondata.columns,
              [newColumn.id]: newColumn,
            },
          },
        },
      }

      dispatch({
        type: "update",
        newState,
      })
      return
    }

    // moving tasks from one column to another
    const startTaskIds = Array.from(start.taskIds)
    startTaskIds.splice(source.index, 1)
    const newStart = {
      ...start,
      taskIds: startTaskIds,
    }

    const finishTaskIds = Array.from(finish.taskIds)
    finishTaskIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      taskIds: finishTaskIds,
    }

    const newState = {
      ...state,
      editProduct: {
        ...state.editProduct,
        jsondata: {
          ...state.editProduct.jsondata,
          columns: {
            ...state.editProduct.jsondata.columns,
            [newStart.id]: newStart,
            [newFinish.id]: newFinish,
          },
        },
      },
    }
    dispatch({
      type: "update",
      newState,
    })
    return
  }

  const handleSave = () => {
    dispatch(saveProduct(state.editProduct))
  }

  const initializeProduct = () => {
    const newState = {
      ...state,
      editProduct: {
        ...state.editProduct,
        jsondata: {
          tasks: {},
          columns: {},
          columnOrder: [],
        },
      },
    }

    dispatch({
      type: "update",
      newState,
    })
  }

  if (!editProduct.id) {
    return (
      <div style={{ minWidth: "100vh" }}>
        <LinearProgress />
      </div>
    )
  }

  if (
    !get(editProduct, "jsondata.columnOrder") ||
    !get(editProduct, "jsondata.columns") ||
    !get(editProduct, "jsondata.tasks")
  ) {
    return (
      <div>
        Invalid Data <button onClick={initializeProduct}>Initialize</button>
      </div>
    )
  }

  return (
    <>
      <PageTitle title="Form Builder" />
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          dispatch({
            type: "addColumn",
          })
        }
      >
        Add Page
      </Button>
      <SaveButton onClick={handleSave} />
      <Grid container spacing={3}>
        <Grid item lg={8} xs={6}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable type="column" droppableId="all-columns">
              {provided => (
                <Container {...provided.droppableProps} ref={provided.innerRef}>
                  {editProduct.jsondata.columnOrder.map((columnId, index) => {
                    // denormalize each column before rendering
                    const column = editProduct.jsondata.columns[columnId]
                    const tasks = column.taskIds.map(taskId => {
                      let result = { ...editProduct.jsondata.tasks[taskId] }
                      if (result.type === "fieldArray" && result.taskIds) {
                        const newTaskIds = result.taskIds.map(
                          tid => editProduct.jsondata.tasks[tid]
                        )
                        result.taskIds = newTaskIds
                      }
                      return result
                    })
                    const columnType = getColumnType(column.template)

                    return (
                      <Column
                        key={column.id}
                        column={column}
                        tasks={tasks}
                        index={index}
                        type={columnType}
                      />
                    )
                  })}
                  {provided.placeholder}
                </Container>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
        <Grid item lg={4} xs={6}>
          <div>{state.modalContent}</div>
        </Grid>
      </Grid>
    </>
  )
}
