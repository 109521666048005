import React, { useState } from "react"
import { navigate } from "gatsby"
import Button from "@material-ui/core/Button"
import { CardElement } from "react-stripe-elements"
import { connect } from "formik"
import { mapKeys } from "lodash"
import { selectPremiumBreakdown } from "../../../state/selectors"
import uuid from "uuid/v4"
import Purchase from "../../../api/purchase"
import { useStateValue } from "../../../state"
import styled from "styled-components"
import { useTheme } from "@material-ui/styles"
import { get } from "lodash"
import Grid from "@material-ui/core/Grid"

const CardContainer = styled.div`
  border: solid thin lightgrey;
  padding: 10px;
  transition: 0.5s all ease-out;

  &:hover {
    border: solid thin ${props => props.borderColor || "black"};
  }
`

function CheckoutForm({ stripe, formik, ...otherProps }) {
  const [error, setError] = useState()
  const [state] = useStateValue()
  const theme = useTheme()
  const { values } = formik
  const premium = selectPremiumBreakdown(values)

  const handleSubmit = async event => {
    const {
      values,
      values: { billingBlock, billingEmail, cardName },
    } = formik

    const { id: product_id, company_id } = state.editProduct

    // We don't want to let default form submission happen here, which would refresh the page.
    event.preventDefault()
    const address = mapKeys(billingBlock, (_, key) => `address_${key}`)

    // https://stripe.com/docs/stripe-js/reference#stripe-create-token
    const token = await stripe.createToken({
      type: "card",
      name: cardName,
      ...address,
    })

    const payment = {
      ...token,
      amount: premium.total.getAmount(),
      idempotency_key: uuid(),
      currency: "cad",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      email: billingEmail,
      order: {
        product_id,
        company_id,
        formdata: values,
        effective_date: values.tournamentDate,
      },
    }

    const result = await Purchase.purchase(payment)

    if (result.status === 200) {
      navigate("/success", {
        state: { ...result.data },
      })
    } else {
      setError("There was an error processing your payment.")
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <CardContainer
          borderFocusColor={get(theme, "palette.primary.main")}
          borderColor={get(theme, "palette.primary.main")}
        >
          <CardElement
            style={{ base: { fontSize: "18px", border: "1px solid black" } }}
          />
          {error && <div>{error}</div>}
        </CardContainer>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </>
  )
}
export default connect(CheckoutForm)
