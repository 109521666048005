import React from "react"
import { Typography } from "@material-ui/core"
import RenderInput from "../Inputs/renderInput"
import Checkbox from "../Inputs/checkbox"
import { Field } from "formik"
import StripeElements from "../Stripe/stripeElements"
import { connect } from "formik"
import Grid from "@material-ui/core/Grid"
import CheckoutCards from "../Inputs/checkoutCards"

const billingBlock = {
  id: "billing",
  label: "Billing",
  name: "billingBlock",
  type: "addressBlock",
}

const email = {
  id: "email",
  label: "Email",
  name: "billingEmail",
  type: "text",
}

const cardName = {
  id: "cardName",
  label: "Card Name",
  name: "cardName",
  type: "text",
}

const checkout = ({ page, onBack, onNext, formik }) => {
  const {
    values,
    values: { billingSameAsContact },
    setFieldValue,
  } = formik

  return (
    <>
      <Grid container justify="space-evenly" spacing={4}>
        <CheckoutCards />
      </Grid>

      <Typography variant="h6">Payment Information</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <RenderInput input={email} />
        </Grid>
        <Grid item xs={6}>
          <RenderInput input={cardName} />
        </Grid>

        <Grid item xs={12}>
          <RenderInput
            input={billingBlock}
            disabled={billingSameAsContact}
            injectedComponent={
              <Field
                component={Checkbox}
                name="billingSameAsContact"
                label="Same as Contact Info"
                onChange={value => {
                  if (value) {
                    setFieldValue("billingBlock", { ...values.contactBlock })
                  }
                }}
              />
            }
          />
        </Grid>

        <StripeElements />
      </Grid>
    </>
  )
}

export default connect(checkout)
