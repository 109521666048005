import React from "react"
import { Link } from "gatsby"
import { Router, Location } from "@reach/router"
import Editor from "../components/Pages/Editor"
import Products from "../components/Pages/Products"
import Preview from "../components/Pages/Preview"
import EditCompany from "../components/Pages/EditCompany"
import Success from "../components/Pages/Success"
import Rating from "../components/Pages/Rating"
import GenPolicy from "../components/Pages/GenPolicy"
import PdfMake from "../components/Pages/PdfMake"
import Modal from "../components/partials/modal"

const IndexPage = () => (
  <div>
    <Link
      to="/success"
      state={{
        amount: 21639,
        currency: "cad",
        receipt_email: "jasontau@gmail.com",
        receipt_number: null,
        receipt_url:
          "https://pay.stripe.com/receipts/acct_1EJ4XBGBUiUrgcKv/ch_1FMHSJGBUiUrgcKvhNh2biOe/rcpt_Fs1V4xpY0qLthWeds75aHzY2zuCH7ox",
        status: "success",
        statusCode: 200,
      }}
    >
      Success Test
    </Link>
  </div>
)

const Index = () => {
  return (
    <>
      {process.env.LIVE_MODE ? (
        <TransitionRouter>
          <Preview path="/" />
          <Success path="success" />
          <GenPolicy path="genPolicy" />
        </TransitionRouter>
      ) : (
        <TransitionRouter>
          <Editor path="products/:productId" />
          <Products path="products" />
          <Preview path="preview" />
          <Rating path="rating" />
          <Success path="success" />
          <IndexPage path="/" />
          <GenPolicy path="genPolicy" />
          <PdfMake path="pdfMake" />
          <EditCompany path="company" />
        </TransitionRouter>
      )}
      <Modal />
    </>
  )
}

const TransitionRouter = props => (
  <Location>
    {({ location }) => (
      <Router location={location} className="router">
        {props.children}
      </Router>
    )}
  </Location>
)

export default Index
