import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepIcon from "./stepicon"
import StepLabel from "@material-ui/core/StepLabel"
import StepConnector from "@material-ui/core/StepConnector"

import { selectPageTitles } from "../../../state/selectors"

const useStyles = makeStyles(theme => ({
  root: {
    // width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  connectorActive: {
    "& $connectorLine": {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorCompleted: {
    "& $connectorLine": {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    "& $connectorLine": {
      borderColor: theme.palette.grey[100],
    },
  },
  connectorLine: {
    transition: theme.transitions.create("border-color"),
    borderWidth: "medium",
  },
  alternativeLabel: {
    left: "calc(-50%)",
    right: "calc(50%)",
  },
}))

export default function CustomizedSteppers({ pageIndex }) {
  const classes = useStyles()
  const totalPages = selectPageTitles()

  const connector = (
    <StepConnector
      classes={{
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
        alternativeLabel: classes.alternativeLabel,
      }}
    />
  )

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={pageIndex} connector={connector}>
        {totalPages.map(label => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon}></StepLabel>
            {/* <StepIcon /> */}
          </Step>
        ))}
      </Stepper>
    </div>
  )
}
