import React, { useState } from "react"
import { Field, connect } from "formik"
import { selectPremiumAffectingQuestions } from "../../../state/selectors"
// import TextField from "@material-ui/core/TextField"
import Checkbox from "../../../components/forms/Inputs/checkbox"
import TextField from "../../forms/Inputs/textField"
import RenderInput from "../../forms/Inputs/renderInput"
import Grid from "@material-ui/core/Grid"
import { premiumEval } from "../../../util/premium"

function RatingFields({ state, formik }) {
  const [premium, setPremium] = useState(0)

  // filter any premium-affecting ones
  const premiumAffectingQuestions = selectPremiumAffectingQuestions(state)
  // console.log('premiumAffectingQuestions', premiumAffectingQuestions)

  try {
    let testResult
    if (formik.values.runEval) {
      const formData = formik.values.useExample ? formik.values.pdf_example_values : formik.values
      testResult = premiumEval(
        formik.values.premium_expression,
        premiumAffectingQuestions,
        formData
      ).toFormat("$0,0.00")
    } else {
      testResult = "Eval Paused"
    }
    if (testResult !== premium) {
      setPremium(testResult)
    }
  } catch (error) {
    console.log("error: ", error)
  }

  return (
    <div>
      <Field
        component={Checkbox}
        name="runEval"
        label="Run Premium Calculation? (may slow down editing)"
      />
      <Field
        component={Checkbox}
        name="useExample"
        label="Use saved example form data?"
      />
      <Grid container>
        <Grid item xs={12} md={8}>
          {premiumAffectingQuestions.map(input => (
            <RenderInput key={input.id} input={input} />
          ))}
        </Grid>
      </Grid>
      <h4>Context Provided</h4>
      {premiumAffectingQuestions.map(input => (
        <div key={input.name}>Variable: {input.name}</div>
      ))}
      <div>Module: Dinero</div>
      <div>Module: moment</div>
      <Field
        component={TextField}
        label="Rating Equation"
        multiline
        name="premium_expression"
        placeholder="Code goes here..."
        margin="normal"
      />
      <div>{`result: ${premium}`}</div>
    </div>
  )
}

export default connect(RatingFields)
