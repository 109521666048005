import React from "react"
import Grid from "@material-ui/core/Grid"
import RenderInput from "../Inputs/renderInput"
import { selectConfirmationQuestions } from "../../../state/selectors"

const confirm = ({ page, onBack, onNext }) => {
  const pages = selectConfirmationQuestions()

  return (
    <div>
      {pages.map(page => (
        <div key={page.title}>
          <h1>{page.title}</h1>
          <Grid container spacing={2}>
            {page.taskIds.map(input => (
              <RenderInput key={input.id} input={input} />
            ))}
          </Grid>
        </div>
      ))}
    </div>
  )
}

export default confirm
