import React, { useState } from "react"
import CheckoutCard from "./checkoutCard"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import { selectPremiumBreakdown } from "../../../state/selectors"
import { connect } from "formik"

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const Cell = styled.td`
  text-align: ${props => props.align || "initial"};
  background-color: lightgrey;
  padding: 4px 10px 4px 10px;
`

function CheckoutCards({ formik }) {
  const { values } = formik
  const {
    premium,
    convenienceFee,
    monthlyFee,
    total,
    totalMonthly,
  } = selectPremiumBreakdown(values)

  const { setFieldValue } = formik
  const [monthlyPayment, setMonthlyPayment] = useState(false)

  const handleSetMonthly = () => {
    setFieldValue('monthlyPayment', true)
    setMonthlyPayment(true)
  }
  const handleSetAnnual = () => setMonthlyPayment(false)

  const selectedFees = monthlyPayment ? monthlyFee : convenienceFee

  return (
    <>
      <CheckoutCard
        premium={premium}
        convenienceFee={monthlyFee}
        total={totalMonthly}
        isMonthly
        isSelected={monthlyPayment}
        onClick={handleSetMonthly}
      />
      <CheckoutCard
        premium={premium}
        convenienceFee={convenienceFee}
        total={total}
        isSelected={!monthlyPayment}
        onClick={handleSetAnnual}
      />
      <Grid item container justify="center">
        <Grid item xs={8}>
          <Table>
            <tbody>
              <tr>
                <Cell>
                  <Typography variant="h6">Premium</Typography>
                </Cell>
                <Cell align="right">
                  <Typography variant="h6">{premium.toFormat("$0,0.00")}</Typography>
                </Cell>
              </tr>
              <tr>
                <Cell>
                  <Typography variant="h6">{"Taxes & Fees"}</Typography>
                </Cell>
                <Cell align="right">
                  <Typography variant="h6">{selectedFees.toFormat("$0,0.00")}</Typography>
                </Cell>
              </tr>
              <tr>
                <Cell>
                  <Typography variant="h6">Total</Typography>
                </Cell>
                <Cell align="right">
                  <Typography variant="h6">{premium.add(selectedFees).toFormat("$0,0.00")}</Typography>
                </Cell>
              </tr>
            </tbody>
          </Table>
        </Grid>
      </Grid>
    </>
  )
}

export default connect(CheckoutCards)