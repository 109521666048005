import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Fab from "@material-ui/core/Fab"
import AddIcon from "@material-ui/icons/Add"
import { getProducts } from "../../../state/actions"
import { useStateValue } from "../../../state"
import Loading from "../../partials/loading"
import PageTitle from "../../partials/pageTitle"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import AddProductModal from "../../partials/addProductModal"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  addButton: {
    float: "right",
    marginRight: 10,
  },
}))

export default function Products() {
  const [state, dispatch] = useStateValue()
  const [openAddProduct, setOpenAddProduct] = useState(false)
  const { products } = state
  const classes = useStyles()

  useEffect(() => {
    if (true) {
      dispatch(getProducts())
    }
  }, [])

  function handleOpenAddProduct() {
    setOpenAddProduct(true)
  }
  function handleClose() {
    setOpenAddProduct(false)
  }

  return (
    <div>
      {products && (
        <Fab
          color="primary"
          size="small"
          className={classes.addButton}
          onClick={handleOpenAddProduct}
        >
          <AddIcon />
        </Fab>
      )}
      <PageTitle title="Products" />
      {products ? (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products &&
              products.map(product => (
                <TableRow key={product.id}>
                  <TableCell component="th" scope="row">
                    <Link to={`/products/${product.id}`}>{product.title}</Link>
                  </TableCell>
                  <TableCell align="right">{product.description}</TableCell>
                  <TableCell align="right">Draft</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      ) : (
        <Loading />
      )}
      <AddProductModal open={openAddProduct} onClose={handleClose} />
    </div>
  )
}
