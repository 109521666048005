import React, { useState } from "react"
import { saveTheme, updateTheme } from "../../../state/actions"
import { useStateValue } from "../../../state"
import { get } from "lodash"
import { SketchPicker } from "react-color"
import styled from "styled-components"
import Dialog from "@material-ui/core/Dialog"
import SaveButton from "../../partials/saveButton"
import Loading from "../../partials/loading"
import PageTitle from "../../partials/pageTitle"

const ColorEdit = styled.button`
  width: 32px;
  height: 32px;
  background-color: ${props => props.color};
`

export default function EditCompany() {
  const [state, dispatch] = useStateValue()
  const [openPicker, setOpenPicker] = useState(false)
  const company = get(state, "user.company")
  const primaryColor = get(company, "theme.palette.primary.main", "#3f51b5")
  const secondaryColor = get(
    company,
    "theme.palette.primary.secondary",
    "#f50057"
  )

  const handleOpenPicker = palette => setOpenPicker(palette)
  const handleClosePicker = () => setOpenPicker(false)

  const setThemeColor = (primaryColor, secondaryColor) => ({
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
  })

  if (!company) return <Loading />

  return (
    <div>
      <PageTitle title="Company Theme" />
      <div>Primary Color</div>
      <ColorEdit
        onClick={() => handleOpenPicker("primary")}
        color={primaryColor}
      />
      <div>Secondary Color</div>
      <ColorEdit
        onClick={() => handleOpenPicker("secondary")}
        color={secondaryColor}
      />
      <div>
        <SaveButton
          type="button"
          color="primary"
          variant="contained"
          onClick={() =>
            dispatch(saveTheme(setThemeColor(primaryColor, secondaryColor)))
          }
        />
      </div>
      <Dialog open={openPicker === "primary"} onClose={handleClosePicker}>
        <SketchPicker
          color={primaryColor}
          onChangeComplete={value =>
            dispatch(updateTheme(setThemeColor(value.hex, secondaryColor)))
          }
        />
      </Dialog>
      <Dialog open={openPicker === "secondary"} onClose={handleClosePicker}>
        <SketchPicker
          color={secondaryColor}
          onChangeComplete={value =>
            dispatch(updateTheme(setThemeColor(primaryColor, value.hex)))
          }
        />
      </Dialog>
    </div>
  )
}
