import React from "react"
import RenderInput from "../../forms/Inputs/renderInput"
import Grid from "@material-ui/core/Grid"

// import Button from "@material-ui/core/Button"
import PremiumBreakdown from "../../partials/premiumBreakdown"

const configure = ({ page, onBack, onNext }) => {
  return (
    <div>
      <Grid
        container
        spacing={2}
      >
        <Grid item container spacing={2} xs={12} md={8}>
          {page.taskIds.map(input => (
            <RenderInput key={input.id} input={input} />
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
        >
          <PremiumBreakdown />

          {/* <div style={{ padding: "20px" }}>
            <Button fullWidth disabled variant="contained">
              Check Out
            </Button>
          </div> */}
        </Grid>
      </Grid>
    </div>
  )
}

export default configure
